import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import TasksApiService from '../../Services/tasks-api-service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class AddTask extends Component {

    static defaultProps = {
        onCreationSuccess: () => {}
      }
    
    state = {error: null}


    // Handle Submit Function 
    // Collects inputs and calls postTask() in task-api-service file
    // Clears values of inputs and calls prop function on success()
    // If error set state with the error
    handleSubmit = ev => {
        ev.preventDefault()
        const { title,description } = ev.target
        
       TasksApiService.postTask({
            title:title.value,
            content:description.value,
        })
        .then(res => {
            
            this.props.onCreationSuccess()
            title.value = ''
            description.value = ''
            
        })
        .catch(res => {
            this.setState({error:res.error})
        })
       
      }


    render() {
      const { error } = this.state 
        return (
            <section className="tasks-container createtasks-container">
              <form onSubmit={this.handleSubmit} className='create-form'>
                  <div role='alert'>
                    {error && <p className='red'>{error}</p>}
                  </div>
                  <div className="title">
                    <label className="create-form-labels" id="title-label" htmlFor="title">Title</label><br/>
                    <input maxLength="60" className="create-inputs" placeholder='title' type="text" name='title' id='title' />
                  </div>
                  <div className="description">
                    <label className="create-form-labels" id="description-label" htmlFor="description">Description</label><br/>
                    <input maxLength="60" className="create-inputs create-input-description" type="text" name='description' id='description' placeholder='Description' />
                  </div>
                  <div className="create-task-controls">
                    <button className="create-button" type='submit'> <FontAwesomeIcon icon={this.props.tick}></FontAwesomeIcon></button>
                    <Link to={'/tasks'} className="back-link"> <div className="back-button">  <FontAwesomeIcon icon={this.props.icon}></FontAwesomeIcon></div></Link>
                  </div>
              </form>             
        </section>
        )
    }
}
