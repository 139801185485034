import React,{Component} from 'react'
import './RegisterPage.css'
import Registration from '../../Components/RegistrationForm/RegistrationForm'

export default class RegisterPage extends Component {

    static defaultProps = {
        location: {},
        history: {
          push: () => {},
        },
      }
       // On Success push history destination to /tasks 
    handleRegisterSuccess = () => {
        const { location, history } = this.props
        const destination = (location.state || {}).from || '/tasks'
        history.push(destination)
      }

    render(){
        return (
        <Registration onRegisterSuccess={this.handleRegisterSuccess}/>
        )
    }
}