import React,{Component} from 'react'

import './CreateTaskPage.css'
import AddTask from '../../Components/AddTask/AddTask'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'


export default class CreateTasksPage extends Component {

    static defaultProps = {
        location: {},
        history: {
          push: () => {},
        },
      }

    // On Success push history destination back to /tasks 
    handleCreationSuccess = () => {
       
        const { location, history } = this.props
        const destination = (location.state || {}).from || '/tasks'
        history.push(destination)
      }


    render(){
        return (
            <AddTask tick={faCheckCircle} icon={faArrowAltCircleLeft} onCreationSuccess={this.handleCreationSuccess}/>
        )
    }
}