import React, {Component} from 'react'
import AuthApiService from '../../Services/auth-api-service'

export default class RegistrationForm extends Component {

    static defaultProps = {
        onRegisterSuccess: () => {}
      }

    state = {error: null}
    // Handle Submit Function 
    // Collects inputs and calls postUser() in auth-api-service file
    // Clears values of inputs and calls prop function on success()
    // If error set state with the error
    handleSubmit = ev => {
        ev.preventDefault()
        let { first_name,last_name, username, password } = ev.target
        
        this.setState({error:null})
    
        AuthApiService.postUser({
            first_name: first_name.value,
            last_name: last_name.value,
            username: username.value,
            password: password.value,
        })
        .then(user => {
            this.props.onRegisterSuccess()
             first_name.value = ''
             last_name.value = ''
             username = ''
             password = ''
        })
        .catch(res => {
            this.setState({error:res.error})
        })
       
      }
      render() {
        const { error } = this.state
        return (
            <section className="container background register-container">
              <h1 className="register">Register</h1>
              <form onSubmit={this.handleSubmit} className='signup-form'>
                <div role='alert'>
                  {error && <p className='red'>{error}</p>}
                </div>
                <div className="first-name">
                  <label id="first-name-label" htmlFor="first-name">First name</label><br/>
                  <input className="inputs" placeholder='First Name' type="text" name='first_name' id='first-name' />
                </div>
                <div className="last-name">
                  <label id="last-name-label" htmlFor="last-name">Last name</label><br/>
                  <input className="inputs" type="text" name='last_name' id='last-name' placeholder='Last Name' />
                </div>
                <div className="email">
                  <label id="email" htmlFor="username">Email</label><br/>
                  <input className="inputs" type="text" name='username' id='username' />
                </div>
                <div className="password">
                  <label id="password" htmlFor="password">Password</label><br/>
                  <input className="inputs" type="password" name='password' id='input-password' />
                </div>
                <button className="signup-button" type='submit'>Sign Up</button>
              </form>
           </section>
         )
      }
}