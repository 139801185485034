import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import './HomePage.css' 


export default class HomePage extends Component {

    render(){
        return (
        <section className="container background">      
            <nav><Link to='/login' id="login">Login</Link><Link to='/register' id="register">Register</Link></nav>
            <header className="logo">Path <span className="dot">.</span></header>
            <div className="description-home">Organize your tasks</div>
        </section>
        )
    }
}