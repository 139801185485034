import React,{Component} from 'react'
import './LoginPage.css' 
import LoginForm from '../../Components/LoginForm/LoginForm'

export default class LoginPage extends Component {

    static defaultProps = {
        location: {},
        history: {
          push: () => {},
        },
      }
       // On Success push history destination to /tasks 
    handleLoginSuccess = () => {
        const { location, history } = this.props
        const destination = (location.state || {}).from || '/tasks'
        history.push(destination)
      }

    render(){
        return (
        <LoginForm onLoginSuccess={this.handleLoginSuccess} />
        )
    }
}