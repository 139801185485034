import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import TasksService from '../../Services/tasks-api-service'
import anime from 'animejs';
import { reverse } from 'dns';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {faBan} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class TaskListItem extends Component {


  // Handler for complete button click 

  handlerComplete(task){
    //Get card and change class
    const selected = task.currentTarget.parentNode.parentNode
    selected.className = 'card-to-remove shine'

    //Get hidden particles and change class so only current event task is being animated 
    const particles = task.currentTarget.parentNode.parentNode.parentNode.nextSibling.childNodes
    for (let i = 0; i < particles.length; i++){
    particles[i].className = "particles";
    }

   //animate
    anime({
      targets: '.card-to-remove',
      duration:10000,
      opacity:0,
      easing:'easeOutElastic(0, 0.7)',
      scale:1.001,
    });
    anime({
      targets: '.particles',
      keyframes: [
        {opacity:0.2},
        { rotate: anime.stagger([10,10])},
        {scale:1.12,duration:200},
        {opacity:0.5},
        {translateX:-150,duration:170},//X left 150
        {translateY:-150,duration:170},//Y up 150
        {opacity:1},
        {translateX:150,duration:150},//X right 150
        {translateY:0,duration:150},//Y down 150
        {translateX:-120,duration:100},//X left 120
        {translateY:-100,duration:100},//Y up 100    
        {translateX:120,duration:100},//X right 120
        {translateY:-30,duration:100},//Y down 70
        {translateX:-100,duration:100},//X left 100 
        {scale:0.3,duration:100},
        {opacity:0},
      ],
      delay: anime.stagger(220,{direction: 'normal'}),      
      direction:reverse,
      easing: 'easeInOutSine(1.2,20)',
      opacity:1,
    })
    this.props.handleCompleted() // counter increase
    setTimeout(this.delete,2300)
  }


  // Delete Function - called for either delete or complete
  delete = () => {
    // Calls deleteTask() from task-service file and prop function called from Task.js to refresh list 
    TasksService.deleteTask(this.props.task.id).then(
      this.props.deleteHandler(this.props.task.id)
     )
   //animation of remaning cards 
     anime({
       targets:'.card',
       translateY: [
        { value: -30, duration: 240, delay:30},
        {value:5, duration:200,delay:20},
        {value:0, duration:200}
      ],
      easing: 'easeInOutSine(1.2,10)',
     }).finished.then(
     anime({
      targets:'.task-holder',
      opacity:1,
      })
     )
   }
  
//Handler for delete button click 
  handlerDelete(task){
   
    //Changes class of selected card
    const selected = task.currentTarget.parentNode.parentNode
    selected.className = 'card-to-remove'
    
    //Animates event target card 
    anime({
      targets: '.card-to-remove',
      translateX: -100,
      duration:2000,
      easing:'easeOutElastic(0, 0.7)'
    })
    //Waits for completion and then executes delete function (above)
    setTimeout(this.delete,1000)
  }


  render() {
    const { task } = this.props
    
    return (
     <section className="task-list">
        <ul className="task-holder">         
          <li className="card" >
            <p className="task-title">{task.title}</p>
            <p>{task.content}</p>
            <div className="task-buttons-container">
              <button onClick={(task) => this.handlerComplete(task)}className="check complete">
                <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
              </button>
              <Link className="edit-link" onClick={()=>this.props.handleFalse()} to={`/edit/${task.id}`}>
                <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
              </Link>
              <button onClick={(task) => this.handlerDelete(task)} className="delete">
                <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
              </button>
            </div>
          </li>
        </ul>
        <div className="particle-wrapper">
          <span  className="completed-particles"></span> 
          <span  className="completed-particles"></span> 
          <span  className="completed-particles"></span> 
          <span  className="completed-particles"></span> 
          <span  className="completed-particles"></span>
        </div>   
     </section>
    )
  }
}

