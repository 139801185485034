import React,{Component} from 'react'

import './TasksPage.css'

import Tasks from '../../Components/Tasks/Tasks'

export default class TasksPage extends Component {
    

    static defaultProps = {
        location: {},
        history: {
          push: () => {},
        },
      }
    
      
     // Prop for Tasks - push history destination back to /tasks 
    handleRefresh = () => {
        const { location, history } = this.props
        const destination = (location.state || {}).from || '/tasks'
        history.push(destination)
      }

    render(){
        return (
        <Tasks  firstLoggedIn={this.firstLoggedIn} refresh={this.handleRefresh}/>
        )
    }
}