import React,{Component} from 'react'


import EditTask from '../../Components/EditTask/EditTask'


export default class EditTasksPage extends Component {

    static defaultProps = {
        location: {},
        history: {
          push: () => {},
        },
      }

     // On Success push history destination back to /tasks 
    handleCreationSuccess = () => {
       
        const { history } = this.props
        const destination =  '/tasks'
        history.push(destination)

      }
    
    render(){
      const { taskId } = this.props.match.params
        return (
            <EditTask id={taskId} onCreationSuccess={this.handleCreationSuccess}/>
        )
    }
}