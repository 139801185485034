import React from 'react';
import HomePage from '../Routes/HomePage/HomePage'
import LoginPage from '../Routes/LoginPage/LoginPage'
import TasksPage from '../Routes/TasksPage/TasksPage'
import RegisterPage from '../Routes/RegisterPage/RegisterPage'
import CreateTaskPage from '../Routes/CreateTaskPage/CreateTaskPage'
import EditTasksPage from '../Routes/EditTaskPage/EditTaskPage'
import './App.css'
import PublicOnlyRoute from '../Utils/PublicOnlyRoute'
import PrivateOnlyRoute from '../Utils/PrivateRoute'


function App() {
  return (
    <main className='App'>
       
      <PublicOnlyRoute
      exact path = {'/'}
      component={HomePage}
      />
      <PublicOnlyRoute
      path={'/login'}
      component={LoginPage}
      />
      <PublicOnlyRoute
      path={'/register'}
      component={RegisterPage}
      />
      <PrivateOnlyRoute
      path={'/tasks'}
      component={TasksPage}
      />
      <PrivateOnlyRoute
       path={'/create'}
       component={CreateTaskPage}
       />
      <PrivateOnlyRoute
       path={'/edit/:taskId'}
       component={EditTasksPage}
       />
    </main>
  );
}

export default App;