import React, {Component} from 'react'

import {Link} from 'react-router-dom'
import TasksApiService from '../../Services/tasks-api-service'
import TokenService from '../../Services/token-service'
import TaskListItem from '../../Components/TaskListItem/TaskListItem'
import anime from 'animejs'
import global from '../../Utils/global'
import {faTasks} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Tasks extends Component {

    state = {
       tasks:[],
       user:[],
    
}
    // When component mounts retrieve tasks and set state, animate their entry
    componentDidMount() {
        
      TasksApiService.getTasks()
        .then(result => {this.setState({tasks:result.tasks,user:result.username})
        }).then(res =>{
            anime({
                targets: '.card',
                translateX: 1000,
                delay: anime.stagger(300, {easing: 'easeOutQuad'})
              });
            
            })
        }

    // When called from tasklistitem - filter the task id from state -- then recall getTasks() (all) from 
    // task-api-service file 
    handleDeleteRefresh = (taskid) => {   
        const refresh = this.state.tasks.filter(tasks => tasks.id !== taskid)
        TasksApiService.getTasks()
        .then(result => 
          {
            this.setState({tasks:refresh})
          })
    }

    // Handle click of logout - clears authToken from token-service file 
    handleLogoutClick = () => {
        TokenService.clearAuthToken()
       
      }
    
    // Renders all the tasks from state 
      renderTasks() {
        return this.state.tasks.map(task =>
          <TaskListItem
            key={task.id}
            task={task}
            deleteHandler={this.handleDeleteRefresh}
            handleFalse={this.handleFalse}
            handleCompleted={this.handleCompleted}
          />
        )
      }
    
    // Prop for TaskListItem - increments counter variable in sessionStorage 
      handleCompleted = () => {
          global.counter ++
          let number = global.counter; 
          TokenService.saveCompleted(number)
      }

    render() {
       
      const icon = <FontAwesomeIcon size="xs" className="font-symbol" icon={faTasks}></FontAwesomeIcon>
    
        return (
            <section className="tasks-container">
            <h1 className="username-phone">{this.state.user}</h1>
              <section className="header-panel">
              <h1 className="username">{this.state.user}</h1>
                  <p className="profile-pic">{TokenService.getCompleted()}</p>   
                  <svg className="svg-container">
                    <Link className="create-link-container" to={'/create'}>
                      <rect rx="5" ry="5" xmlns="http://www.w3.org/2000/svg" className="shape"  height="50" width="100" />
                      <svg height="40" width="50" x="25%" y="10%" className="svg-icon">
                        {icon}
                      </svg>
                    </Link>
                  </svg>                                      
                  <svg className="svg" height="50" width="100" >
                    <Link to={'/login'} fill="currentcolor" className="logout-link" onClick={this.handleLogoutClick}>
                      <rect rx="5" ry="5" xmlns="http://www.w3.org/2000/svg" className="shape"  height="50" width="100" />
                      <text xmlns="http://www.w3.org/2000/svg" color="white" fill="currentcolor" className="svg_text" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
                        Logout
                      </text>
                    </Link>
                  </svg>        
              </section>
              <section>
                  {this.renderTasks()}
              </section>
            </section>
        )
    }
}
