import React, {Component} from 'react'
import AuthApiService from '../../Services/auth-api-service'
import TokenService from '../../Services/token-service'

export default class LoginForm extends Component {

    static defaultProps = {
        onLoginSuccess: () => {}
      }

    state = {error: null}

    // Handle Submit Function 
    // Collects inputs and calls postLogin() in auth-api-service file
    // Clears values of inputs, and calls save resulting authToken then calls prop function on success()
    // If error set state with the error
    handleSubmit = ev => {
        ev.preventDefault()
        const { username, password } = ev.target
      
        this.setState({error:null})
    
        AuthApiService.postLogin({
            username: username.value,
            password:password.value,
        })
        .then(res => {
            username.value = ''
            password.value = '' 
            TokenService.saveAuthToken(res.authToken)
            this.props.onLoginSuccess()    
        })
        .catch(res => {
            this.setState({error:res.error})
        })
       
      }
      render() {
        const { error } = this.state
        return (
            <section className="container background login-container">
              <h1 className="login">Login</h1>
              <form onSubmit={this.handleSubmit} className='login-form'>
                <div role='alert'>
                  {error && <p className='red'>{error}</p>}
                </div>
                <div className="email">
                  <label id="email" htmlFor="username">Email</label><br/>
                  <input className="inputs" type="text" name='username' id='username' />
                </div>
                <div className="password">
                  <label id="password" htmlFor="password">Password</label><br/>
                  <input className="inputs" type="password" name='password' id='input-password' />
                </div>
                <button className="button-login" type='submit'>Login</button>
              </form>
        </section>
         )
      }
}