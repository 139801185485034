import TokenService from './token-service'
import config from '../config'

const TasksApiService = {
  getTasks() {
    return fetch(`${config.API_ENDPOINT}/tasks`, {
      headers: {
        'authorization':`bearer ${TokenService.getAuthToken()}`,
      },
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(e => Promise.reject(e))
          : res.json()
      )
  },
  getTask(taskId) {
    return fetch(`${config.API_ENDPOINT}/tasks/${taskId}`, {
      headers: {
        'authorization': `bearer ${TokenService.getAuthToken()}`,
      },
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(e => Promise.reject(e))
          : res.json()
      )
  },
  patchTask(task,taskId){
    return fetch(`${config.API_ENDPOINT}/tasks/${taskId}`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${TokenService.getAuthToken()}`,
      },
      body:JSON.stringify(task)
    })
    .then(res =>
      (!res.ok)
      ? res.json().then(e => Promise.reject(e))
      :res.json()
      )
  },
  postTask(task) {
    return fetch(`${config.API_ENDPOINT}/tasks/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${TokenService.getAuthToken()}`,
      },
      body: JSON.stringify(task)
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(e => Promise.reject(e))
          : res.json()
      )
  },
  deleteTask(taskId){
    return fetch(`${config.API_ENDPOINT}/tasks/${taskId}`, {
      method: 'DELETE',
      headers: {
        'authorization': `bearer ${TokenService.getAuthToken()}`,
      }
    })
    .then(res =>
      (!res.ok)
        ? res.then(e => Promise.reject(e))
        : res
    )
  }
}

export default TasksApiService
