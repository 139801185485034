import React, {Component} from 'react'

import {Link} from 'react-router-dom'
import TasksApiService from '../../Services/tasks-api-service'


export default class EditTask extends Component {

    state = {
        task_data: []
    }

    static defaultProps = {
        onCreationSuccess: () => {}
      }

    // Handle Submit Function 
    // Collects inputs and calls postTask() in task-api-service file
    // Clears values of inputs and calls prop function on success()
    // If error set state with the error
    handleSubmit = ev => {
        ev.preventDefault()
        const { title,description } = ev.target
        
       TasksApiService.patchTask({
            title:title.value,
            content:description.value,
       },this.props.id)
        .then(res => {
            
            this.props.onCreationSuccess()
            title.value = ''
            description.value = ''
            
        })
        .catch(res => {
            this.setState({error:res.error})
        })
       
    }

    //When component mounts get the task.id passed in as props and call getTask() from task-api-service file
    // Then set the state with the result -- used in the display of default values on the form inputs 
    componentDidMount(){
            TasksApiService.getTask(this.props.id)
            .then(result => {
                this.setState({
                    task_data: result.task[0]
                })
             
            })
        }


    render() {
        const data = this.state.task_data
        const taskTitle = data.title
        const taskDescription = data.content

        return (
            <section className="tasks-container ">
                <section className="side-panel">   
                </section>
                <form onSubmit={this.handleSubmit} className='create-form'>
                    <div className="title">
                        <label className="create-form-labels" id="title-label" htmlFor="title">Title</label><br/>
                        <input maxLength="60" className="create-inputs" placeholder='title' defaultValue={taskTitle} type="text" name='title' id='title' />
                    </div>
                    <div className="description">
                        <label className="create-form-labels" id="description-label" htmlFor="description">Description</label><br/>
                        <input className="create-inputs create-input-description" defaultValue={taskDescription} type="text" maxLength="60"name='description' id='description' placeholder='Description' />
                    </div>
                    <div className="create-task-controls">
                        <button className="signup-button" type='submit'>Done</button>
                        <Link to={'/tasks'} className="back-link"><div className="back-button">Back</div></Link>
                    </div>
                </form>           
        </section>
        )
    }
}
